import Vue from 'vue'
import Vuex from 'vuex'
import communeStore from './commune'
import regionStore from './region'
import previsionStore from './prevision'
import clientStore from './client'
import enfermeroStore from './enfermero'
import agreementStore from './agreement'
import orderStore from './order'
import serviceStore from './service'
import AuthenticationStore from './authentication'
import tagStore from './tag'
import groupStore from './group'
import clientEventStore from './clientEvent'
import mailingStore from './mailing'
import landingPageStore from './landingPage'
import surveyStore from './survey'
import educationStore from './education'
import forumStore from './forum'
import packagesStore from './packages'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    landingPageStore,
    AuthenticationStore,
    communeStore,
    regionStore,
    previsionStore,
    clientStore,
    enfermeroStore,
    agreementStore,
    orderStore,
    serviceStore,
    tagStore,
    groupStore,
    clientEventStore,
    mailingStore,
    surveyStore,
    educationStore,
    forumStore,
    packagesStore,
  },
})
