import axios, { getHeaders } from '@/plugins/axios'
import fileDownload from 'js-file-download'

/* -------------------------------- OrderForConsumer / Pedido B2C ------------------------------- */
const listOrderForConsumer = (_, { params } = {}) => {
  const headers = getHeaders()
  return axios
    .get('/sale/b2c/pedido/', { params, headers })
    .catch((err) => err.response)
}

const getOrderForConsumer = (_, { id, params }) => {
  const headers = getHeaders()
  return axios
    .get(`/sale/b2c/pedido/${id}/`, { params, headers })
    .catch((err) => err.response)
}

const createOrderForConsumer = (_, { data }) => {
  const headers = getHeaders()
  return axios
    .post('/sale/b2c/pedido/', data, headers)
    .catch((err) => err.response)
}

const updateOrderForConsumer = (_, { id, data }) => {
  const headers = getHeaders()
  return axios
    .put(`/sale/b2c/pedido/${id}/`, data, headers)
    .catch((err) => err.response)
}

const sendOrderProposal = (_, { orderId }) => {
  const headers = getHeaders()
  return axios
    .post(`/sale/orders/${orderId}/transicion-enviar-presupuesto`, {}, headers)
    .catch((err) => err.response)
}

const deleteOrderForConsumer = (_, { id }) => {
  const headers = getHeaders()
  return axios
    .delete(`/sale/b2c/pedido/${id}/`, headers)
    .catch((err) => err.response)
}

const changeStateForConsumer = (_, { orderId, action, comment }) => {
  const headers = getHeaders()
  const data = { action, comment }
  return axios
    .post(`/sale/b2c/pedido/${orderId}/change_state/`, data, headers)
    .catch((err) => err.response)
}

const updateBeneficiaries = (_, { orderId, data }) => {
  const headers = getHeaders()
  return axios
    .put(`/sale/b2c/pedido/${orderId}/update_beneficiaries/`, data, headers)
    .catch((err) => err.response)
}

/* -------------------------------- OrderForBusiness / Pedido B2B ------------------------------- */
const listOrderForBusiness = (_, { params } = {}) => {
  const headers = getHeaders()
  return axios
    .get('/sale/b2b/pedido/', { params, headers })
    .catch((err) => err.response)
}

const getOrderForBusiness = (_, { id, params }) => {
  const headers = getHeaders()
  return axios
    .get(`/sale/b2b/pedido/${id}/`, { params, headers })
    .catch((err) => err.response)
}

const createOrderForBusiness = (_, { data }) => {
  const headers = getHeaders()
  return axios
    .post('/sale/b2b/pedido/', data, { headers })
    .catch((err) => err.response)
}

const copyOrderForBusiness = (_, { data }) => {
  const headers = getHeaders()
  return axios
    .post('/sale/b2b/copy_order/', data, headers)
    .catch((err) => err.response)
}

const updateOrderForBusiness = (_, { id, data }) => {
  const headers = getHeaders()
  return axios
    .put(`/sale/b2b/pedido/${id}/`, data, headers)
    .catch((err) => err.response)
}

const deleteOrderForBusiness = (_, { id }) => {
  const headers = getHeaders()
  return axios
    .delete(`/sale/b2b/pedido/${id}/`, headers)
    .catch((err) => err.response)
}

const exportOrdersExcel = (_, { params } = {}) => {
  const headers = getHeaders()
  return axios
    .get('/sale/b2b/excel-export', { params, headers, responseType: 'blob' })
    .then((response) => {
      fileDownload(response.data, 'Pedidos.xlsx')
      return response
    })
    .catch((err) => err.response)
}

const exportB2BVisitsExcel = (_, { params } = {}) => {
  const headers = getHeaders()
  return axios
    .get('/sale/b2b/pedido/generate_visit_excel/', { params, headers, responseType: 'blob' })
    .then((response) => {
      fileDownload(response.data, 'visitas_b2b.xlsx')
      return response
    })
    .catch((err) => err.response)
}

const exportB2CVisitsExcel = (_, { params } = {}) => {
  const headers = getHeaders()
  return axios
    .get('/sale/b2c/pedido/generate_visit_excel/', { params, headers, responseType: 'blob' })
    .then((response) => {
      fileDownload(response.data, 'visitas_b2c.xlsx')
      return response
    })
    .catch((err) => err.response)
}

const getExportTemplates = (_, { params } = {}) => {
  const headers = getHeaders()
  return axios
    .get('/sale/export_template', { headers, params })
    .then((response) => {
      return response.data
    })
    .catch((err) => err.response)
}

const sendOrderQuoteForBusiness = (_, { orderId, recipientList }) => {
  const headers = getHeaders()
  const data = { recipient_list: recipientList }
  return axios
    .post(`/sale/b2b/pedido/${orderId}/send_quote/`, data, headers)
    .catch((err) => err.response)
}

const changeStateForBusiness = (_, { orderId, action, comment }) => {
  const headers = getHeaders()
  const data = { action, comment }
  return axios
    .post(`/sale/b2b/pedido/${orderId}/change_state/`, data, headers)
    .catch((err) => err.response)
}

const getMaxDiscountRate = (_, { agreementId }) => {
  const headers = getHeaders()
  return axios
    .get(`/sale/b2b/max-discount/${agreementId}/`, { headers })
    .catch((err) => err.response)
}

const setSkusErrors = ({ commit }, skus) => { 
  commit('SET_SKUS_ERRORS', skus)
}

/* ------------------------- OrderLogEvent / Evento Historial de Pedido ------------------------- */
const listLogsForConsumer = (_, { orderId } = {}) => {
  const headers = getHeaders()
  return axios
    .get(`/sale/b2c/pedido/${orderId}/logs/`, { headers })
    .catch((err) => err.response)
}

const listLogsForBusiness = (_, { orderId } = {}) => {
  const headers = getHeaders()
  return axios
    .get(`/sale/b2b/pedido/${orderId}/logs/`, { headers })
    .catch((err) => err.response)
}

const approveOrder = (_, { orderId, data } = {}) => {
  const headers = getHeaders()
  return axios
    .post(`/sale/payment/confirm/${orderId}/`, data, { headers })
    .catch((err) => err.response)
}

/* ------------------------- OrderResults / Documentos con los resultados de exámenes ------------------------- */
const createOrderResult = (_, { data } = {}) => {
  const headers = { ...getHeaders(), 'Content-Type': 'multipart/form-data' }
  return axios
    .post(`/sale/order_results/`, data, { headers })
    .catch((err) => err.response)
}

const updateOrderResult = (_, { id, data } = {}) => {
  const headers = getHeaders()
  return axios
    .put(`/sale/order_results/${id}/`, data, { headers })
    .catch((err) => err.response)
}

const deleteOrderResult = (_, { id } = {}) => {
  const headers = getHeaders()
  return axios
    .delete(`/sale/order_results/${id}/`, { headers })
    .catch((err) => err.response)
}

const invoiceOrder = (_, { orderId, data } = {}) => {
  const headers = getHeaders()
  return axios
    .post(`/sale/orders/${orderId}/invoice/`, data, { headers })
    .catch((err) => err.response)
}

/* --------------------------------- OrderComment / Comentarios --------------------------------- */
const listOrderComments = (_, { orderId } = {}) => {
  const headers = getHeaders()
  const params = { order: orderId }
  return axios
    .get('/sale/order_comments/', { headers, params })
    .catch((err) => err.response)
}

const createOrderComment = (_, { data }) => {
  const headers = getHeaders()
  return axios
    .post('/sale/order_comments/', data, { headers })
    .catch((err) => err.response)
}

export default {
  listOrderForConsumer,
  getOrderForConsumer,
  createOrderForConsumer,
  updateOrderForConsumer,
  deleteOrderForConsumer,
  sendOrderProposal,
  listOrderForBusiness,
  getOrderForBusiness,
  createOrderForBusiness,
  updateOrderForBusiness,
  deleteOrderForBusiness,
  sendOrderQuoteForBusiness,
  listLogsForConsumer,
  listLogsForBusiness,
  approveOrder,
  createOrderResult,
  updateOrderResult,
  deleteOrderResult,
  invoiceOrder,
  exportOrdersExcel,
  changeStateForBusiness,
  listOrderComments,
  createOrderComment,
  getExportTemplates,
  changeStateForConsumer,
  getMaxDiscountRate,
  updateBeneficiaries,
  copyOrderForBusiness,
  exportB2BVisitsExcel,
  exportB2CVisitsExcel,
  setSkusErrors
}
