import * as getters from './getters';
import mutations from './mutations'
import actions from './actions'
import { getField, updateField } from 'vuex-map-fields'

const orderStore = {
  namespaced: true,
  state: {
    formNew: {
      cliente: null,
      fecha: undefined,
      enfermero: null,
      convenio: null,
      requiereSupervision: false,
      enAyuno: false,
      comentarioInterno: null,
      comentarioExterno: null,
      discountRate: null,

      items: [],
      itemsB2B: {
        Prestaciones: [],
        Insumos: [],
        Medicamentos: [],
        Equipos: [],
      },

      idPresupuesto: null,
      readingMode: false,
    },
    beneficiaries: {},
    openPanels: [],
    skusErrors: [],
  },
  getters: {
    ...getters,
    getField,
  },
  mutations: {
    ...mutations,
    updateField,
  },
  actions,
}

export default orderStore
